// General
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Material UI
import Breadcrumbs from "@mui/material/Breadcrumbs";
// Styles
import "./siteBreadcrumb.module.css";
// Components
import { Typography } from "@mui/material";
import CustomLink from "../commons/link/CustomLink";
// Hooks
import { generateFullSitePath } from "../../hooks/useNavigateToSite";
// Store
import { selectSites } from "../../store";

/**
 * Component that represents the sites breadcrumb
 * @returns JSX.Element
 */
const SiteBreadcrumb = (): JSX.Element => {
  const { selectedSite } = useSelector(selectSites);
  const location = useLocation();

  return (
    <Breadcrumbs>
      {selectedSite?.ancestors?.length &&
        selectedSite?.ancestors.map(({ id, name }) => (
          <CustomLink
            key={`custom-link-${id}`}
            to={generateFullSitePath(location.pathname, selectedSite.id, id)}
            className="config-breadcrumb__item"
            divWrapper
          >
            {name}
          </CustomLink>
        ))}
      {selectedSite && (
        <Typography className="config-breadcrumb__item config-breadcrumb__leaf">
          {selectedSite.name}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default SiteBreadcrumb;
