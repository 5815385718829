// General
import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
// Components
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Button } from "dms-lib";
// Styles
import "./siteLeafStyles.module.css";
// Store
import { selectSites, useStoreDispatch } from "../../store";
// Models
import { FlatSiteTreeNodeDto } from "../../models/sites/SiteTreeNodeDto";
import useNavigateToSite from "../../hooks/useNavigateToSite";
import { sitesActions } from "../../store/sites";

interface SiteLeafProps {
  siteData: FlatSiteTreeNodeDto;
  depth: number;
  // eslint-disable-next-line
  toggleAccordion: (event: any, forceState?: boolean) => void;
  // eslint-disable-next-line
  isCollapsable?: boolean;
}

/**
 * Component that represents the site leafs.
 * Site leafs can be parents of other leafs, in that case collapsable is set to true
 * @param siteData - the site information, contains name, id and childsites
 * @param depth - depth in the site tree hierarchy
 * @toggleAccordion - action for the accordion
 * @isCollapsable - indicates weather there's more childs or not
 * @returns JSX.Element
 */
const SiteLeaf = ({
  siteData,
  depth,
  toggleAccordion,
  isCollapsable,
}: PropsWithChildren<SiteLeafProps>): JSX.Element => {
  const { id, name } = siteData;
  const navigateToSite = useNavigateToSite();

  const { selectedSiteId } = useSelector(selectSites);
  const dispath = useStoreDispatch();

  const active = id === selectedSiteId;

  const handleSiteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dispath(sitesActions.updateLastSnap(siteData));
    dispath(sitesActions.updateCurrentSnap(siteData));
    navigateToSite(siteData.id);
  };

  return (
    <AccordionSummary
      style={{ "--depth": `${depth < 5 ? depth : 5}` } as React.CSSProperties}
      className={`config-site__leaf-summary${active ? " active" : ""}${
        depth <= 1 && !active ? ` depth${depth}` : ""
      }`}
      onClick={(e) => handleSiteClick(e)}
      expandIcon={
        isCollapsable ? (
          <Button
            color="inherit"
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              toggleAccordion(event);
            }}
            icon
          >
            <ExpandMoreRoundedIcon />
          </Button>
        ) : null
      }
    >
      <Typography
        className="config-site__leaf-text"
        data-cr={`config-site__leaf-${id}`}
      >
        {name}
      </Typography>
    </AccordionSummary>
  );
};

export default SiteLeaf;
