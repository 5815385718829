// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Actions
import { cloneDeep } from "lodash";
import sitesExtraActions from "../extraActions";
// Store
import { SitesState, FLAT_SITE_PLACEHOLDER } from "..";
import { rootActions } from "../../root";
// Models
import { MenuTabRoute } from "../../../models/configMenu/MenuTabRoute";
import { FlatSiteTreeNodeDto } from "../../../models/sites/SiteTreeNodeDto";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

// Find the index of the parent site in the siteRoot
const findParentIndex = (state: SitesState) => {
  return state.siteRoot.data?.findIndex(
    (site) => site.id === state.selectedSiteId
  );
};

// Create a placeholder site to be used as an temporary-entry for a new site
const prepareNewSite = (parentSite: FlatSiteTreeNodeDto) => {
  const newSite = cloneDeep(FLAT_SITE_PLACEHOLDER);
  newSite.timeZone = parentSite.timeZone;
  newSite.ancestors = [
    ...parentSite.ancestors,
    { id: parentSite.id, name: parentSite.name },
  ];
  newSite.depth = parentSite.depth + 1;
  newSite.parentId = parentSite.id;
  return newSite;
};

/**
 * @returns The index to insert the new site in the siteRoot - 0 if it should be appended at the end of the list
 */
const findChildEntryIndex = (state: SitesState, parentIndex: number) => {
  if (parentIndex === 0) return 0;

  let appendChildIndex = parentIndex;
  if (parentIndex > 0) {
    for (let i = parentIndex + 1; i < state.siteRoot.data?.length; i++) {
      if (
        !state.siteRoot.data[i].ancestors.some(
          ({ id }) => id === state.selectedSiteId
        )
      ) {
        break;
      }
      appendChildIndex = i;
    }
  }

  if (appendChildIndex >= state.siteRoot.data?.length - 1) return 0;

  return appendChildIndex + 1;
};

const fromRootReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Receives a boolean : true if it has to update lastSnapshot, false otherwise
  builder.addCase(
    rootActions.changeTab,
    (state: SitesState, action: PayloadAction<MenuTabRoute>) => {
      state.siteTabActive = action.payload === MenuTabRoute.Generic;
    }
  );

  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder.addCase(rootActions.activateCreationMode, (state: SitesState) => {
    if (!state.siteTabActive) return;

    const parentIndex = findParentIndex(state);
    const newSite = prepareNewSite(state.siteRoot.data[parentIndex]);
    const newSiteIndex = findChildEntryIndex(state, parentIndex);

    // Update parent (if needed)
    const parentSite = state.siteRoot.data?.[parentIndex];
    const parentSnap = cloneDeep(parentSite);
    state.siteParentSnap = parentSnap;
    if (!parentSite.hasChild) parentSite.hasChild = true;

    // Expand parent (if needed)
    if (!state.sitesExpanded[parentSite.id]?.expanded) {
      state.sitesExpanded[parentSite.id] = {
        expanded: true,
        parentId: parentSite.parentId,
      };
    }

    // Add the new site on the correct index (add with push in case of the last element)
    if (newSiteIndex === 0) state.siteRoot.data.push(newSite);
    else state.siteRoot.data.splice(newSiteIndex, 0, newSite);

    // Update the remaining states with the new site
    state.siteLastSnap = state?.selectedSite;
    state.selectedSite = newSite;
    state.selectedSiteId = state.selectedSite.id;
    state.siteBeingManipulated = cloneDeep(newSite);
  });
  builder.addCase(rootActions.activateEditionMode, (state: SitesState) => {
    if (!state.siteTabActive) return;

    state.siteBeingManipulated = cloneDeep(state.selectedSite);
    state.siteLastSnap = state.selectedSite;
    state.selectedSiteId = state.selectedSite?.id;
  });
  // Receives a boolean : true if it has to update lastSnapshot, false otherwise
  builder.addCase(
    rootActions.activateReadOnlyMode,
    (state: SitesState, action: PayloadAction<boolean>) => {
      if (!state.siteTabActive) return;

      if (action.payload) {
        state.siteBeingManipulated = undefined;
        state.siteLastSnap = state.selectedSite;
      } else {
        if (!state.selectedSite?.id) return;

        if (state.siteBeingManipulated?.id === FLAT_SITE_PLACEHOLDER.id) {
          // In case of canceling a new site creation
          // The site should be removed and the parent should revert the changes)
          const newEntryIndex = state.siteRoot.data.findIndex(
            (site) => site.id === FLAT_SITE_PLACEHOLDER.id
          );
          if (newEntryIndex !== -1) {
            state.siteRoot.data.splice(newEntryIndex, 1);
          }
          const parentIndex = state.siteRoot.data.findIndex(
            (site) => site.id === state?.siteParentSnap?.id
          );
          if (parentIndex !== -1 && state.siteParentSnap) {
            state.siteRoot.data[parentIndex] = state.siteParentSnap;
          }
          state.selectedSite = cloneDeep(state.siteParentSnap);
          state.selectedSiteId = state.selectedSite?.id;
          state.siteParentSnap = undefined;
        } else if (state.siteLastSnap) {
          state.selectedSite = state.siteLastSnap;
          state.selectedSiteId = state.selectedSite?.id;
        }
        state.siteBeingManipulated = undefined;
      }
    }
  );
};

const siteGetAllReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  builder
    .addCase(sitesExtraActions.getAll.pending, (state: SitesState) => {
      state.siteRoot = {
        ...state.siteRoot,
        loading: true,
      };
      state.siteError = null;
    })
    .addCase(
      sitesExtraActions.getAll.fulfilled,
      (state: SitesState, action: PayloadAction<FlatSiteTreeNodeDto[]>) => {
        state.siteRoot = {
          loading: false,
          data: action.payload,
        };
      }
    )
    .addCase(sitesExtraActions.getAll.rejected, (state: SitesState, action) => {
      state.siteRoot = {
        loading: false,
        data: null,
      };
      state.siteError = {
        label: "site.sitesNotFound",
        message: action.error.message,
      };
    });
};

const siteCreateReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.createSite.pending, (state: SitesState) => {
      state.siteError = null;
      state.siteCrudLoading = true;
    })
    .addCase(sitesExtraActions.createSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.createSite.rejected,
      (state: SitesState, action) => {
        state.siteError = {
          label: "site.failToCreateSite",
          message: action.error.message,
        };
        state.siteCrudLoading = false;
      }
    );
};

const siteUpdateReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.updateSite.pending, (state: SitesState) => {
      state.siteError = null;
      state.siteCrudLoading = true;
    })
    .addCase(sitesExtraActions.updateSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.updateSite.rejected,
      (state: SitesState, action) => {
        state.siteCrudLoading = false;
        state.siteError = {
          label: "site.failToUpdateSite",
          message: action.error.message,
        };
      }
    );
};

const siteDeleteReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.deleteSite.pending, (state: SitesState) => {
      state.siteCrudLoading = true;
      state.siteError = null;
    })
    .addCase(sitesExtraActions.deleteSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.deleteSite.rejected,
      (state: SitesState, action) => {
        state.siteCrudLoading = false;
        state.siteError = {
          label: "site.failToDeleteSite",
          message: action.error.message,
        };
      }
    );
};

const sitesExtraReducers = (
  builder: ActionReducerMapBuilder<SitesState>
): any => {
  fromRootReducer(builder);
  siteGetAllReducer(builder);
  siteCreateReducer(builder);
  siteUpdateReducer(builder);
  siteDeleteReducer(builder);
};

export default sitesExtraReducers;
