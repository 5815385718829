// General
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Store
import { isUnity } from "compass-commons";
import { selectSites, useStoreDispatch } from "../../store";
// Router
import { ROOTPATH } from "../../router/Routes";
import { sitesActions } from "../../store/sites";

interface navigateToSiteOptions {
  replace?: boolean;
  disableNavigation?: boolean;
}

/**
 * Functions that builds the full path based on the old and new sites
 * @param pathname - location pathname
 * @param oldSiteId - old site where we are infering the new path
 * @param newSiteId - new site to be replacing the old one
 * @returns
 */
export const generateFullSitePath = (
  pathname: string,
  oldSiteId: string,
  newSiteId: string
): string => {
  const location = pathname.split(`${oldSiteId}/`)?.[1] || "";
  return `${ROOTPATH}/${newSiteId}/${location}`;
};

/**
 * React Hook that helps to navigate through the site tree
 * @returns Function that receives two parameters:
 *  - siteId (optional) - if a site is passed then we are navigating to that one,
 * if not, then we go to the root as fallback
 * - siteOptionss
 */
const useNavigateToSite = (): ((
  siteId?: string,
  siteOptions?: navigateToSiteOptions
) => void) => {
  const { selectedSiteId, siteRoot, siteBeingManipulated } =
    useSelector(selectSites);
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();

  const navigateToSite = (
    toSiteId?: string,
    siteOptions: navigateToSiteOptions = { disableNavigation: false }
  ) => {
    const newSiteId = toSiteId ?? siteRoot?.data[0]?.id;

    // If the site is the same and there's no change in progress, then don't navigate
    if (!newSiteId || (newSiteId === selectedSiteId && !siteBeingManipulated))
      return;

    const fullPath = generateFullSitePath(
      isUnity || !isDMS ? window.location.hash : window.location.pathname,
      selectedSiteId || newSiteId,
      newSiteId
    );

    if (siteOptions?.disableNavigation) return;

    dispatch(sitesActions.changeSelectedSiteId(newSiteId));
    navigate(fullPath, {
      replace: siteOptions.replace ?? false,
    });
  };

  return navigateToSite;
};

export default useNavigateToSite;
