import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Components
import { RootState } from "..";
// Models
import {
  SiteAncestor,
  FlatSiteTreeNodeDto,
  SitesExpanded,
} from "../../models/sites/SiteTreeNodeDto";
// Reducers
import sitesExtraReducers from "./extraReducers";
// Actions
import sitesExtraActions from "./extraActions";
import { ConfigModes, readOnlyMode } from "../root";

/* eslint-disable no-param-reassign */

interface GenericState<T> {
  data?: T;
  loading: boolean;
}

type SiteErrorType = { label: string; message: string };

export const SITE_PLACEHOLDER = {
  id: "placeholder_id",
  name: "Untitled site",
  timeZone: "EST5EDT",
  isTimeZoneInherited: true,
  childSites: [],
};

export const FLAT_SITE_PLACEHOLDER: FlatSiteTreeNodeDto = {
  id: "placeholder_id", // Can`t be null/undefined to avoid errors
  name: "Untitled site",
  timeZone: "EST5EDT",
  isTimeZoneInherited: true,
  parentId: null,
  ancestors: [],
  depth: 0,
  hasChild: false,
};

export type SitesState = {
  siteTabActive: boolean;
  selectedSiteId?: string; // The ID is need because there is no guarantee that the selectedSite is always available
  selectedSite?: FlatSiteTreeNodeDto;
  siteParentSnap?: FlatSiteTreeNodeDto; // Saves the all the node structure where the new site lives
  siteLastSnap?: FlatSiteTreeNodeDto;
  siteBeingManipulated?: FlatSiteTreeNodeDto;
  siteRoot: GenericState<FlatSiteTreeNodeDto[]>;
  sitesExpanded: SitesExpanded;
  siteError?: SiteErrorType;
  siteCrudLoading?: boolean;
};

const SLICE_NAME = "sites";

const initialState: SitesState = {
  siteTabActive: false,
  selectedSiteId: undefined, // ID of the current selected Site
  siteBeingManipulated: undefined,
  siteParentSnap: undefined, // Keeps track of the parent state before site config actions
  siteLastSnap: undefined, // Keeps track of the last state before site config actions
  selectedSite: undefined, // Keeps track of the current state when not in readonly
  siteRoot: {
    loading: false,
    data: undefined,
  },
  sitesExpanded: {},
  siteError: undefined,
  siteCrudLoading: false,
};

/**
 * Slice to manipulate all operations related to sites
 */
export const sitesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateCurrentSnap: (
      state: SitesState,
      action: PayloadAction<FlatSiteTreeNodeDto>
    ) => {
      state.selectedSite = action.payload;
      state.selectedSiteId = action.payload.id;
    },
    updateLastSnap: (
      state: SitesState,
      action: PayloadAction<FlatSiteTreeNodeDto>
    ) => {
      state.siteLastSnap = action.payload;
    },
    // When changing the site we want to update the selected site as well as the selectedSiteInfo
    changeSelectedSiteId: (
      state: SitesState,
      action: PayloadAction<string>
    ) => {
      state.selectedSiteId = action.payload;
      if (state.selectedSite?.id !== action.payload) {
        const siteInfo = state.siteRoot.data?.find(
          (site) => site.id === action.payload
        );
        if (siteInfo) {
          state.selectedSite = siteInfo;
        }
      }
    },
    changeExpandedSites: (
      state: SitesState,
      action: PayloadAction<SitesExpanded>
    ) => {
      state.sitesExpanded = { ...state.sitesExpanded, ...action.payload };
    },
  },
  extraReducers: sitesExtraReducers,
});

// Action creators are generated for each case reducer function
export const sitesActions = { ...sitesSlice.actions, ...sitesExtraActions };

// Sites Selectors
export const selectIsPlaceholderSite = ({ sites }: RootState): boolean =>
  sites.selectedSiteId === SITE_PLACEHOLDER.id;

export const selectedSiteAncestors = ({ sites }: RootState): SiteAncestor[] =>
  sites.selectedSite?.ancestors;
export const selectedSiteParentId = ({ sites }: RootState): string =>
  sites.selectedSite?.parentId;
export const selectSiteCRUDLoading = ({ sites }: RootState): boolean => {
  return sites.siteCrudLoading;
};

export const selectSiteBeingManipulated = (
  rootState: RootState
): FlatSiteTreeNodeDto | undefined => {
  const siteManipulatedSelector = Object.freeze({
    [ConfigModes.CREATE]: () => rootState.sites?.selectedSite,
    [ConfigModes.EDIT]: () => rootState.sites.selectedSite,
  });
  return siteManipulatedSelector[rootState.root.configMode]?.();
};

export const siteBeingManipulated = (rootState: RootState): boolean => {
  return (
    !readOnlyMode(rootState) &&
    rootState.sites?.selectedSiteId ===
      rootState.sites?.siteBeingManipulated?.id
  );
};

export default sitesSlice.reducer;
