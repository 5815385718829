// General
import React, { useEffect, useRef, useMemo, useCallback } from "react";
import { useI18n } from "compass-commons";
// Styles
import "./siteTreeStyles.module.css";
// Components
import { Virtuoso } from "react-virtuoso";
import { Accordion } from "dms-lib";
// Context
// Hooks
// Store
import { useSelector } from "react-redux";
import SiteLeaf from "./SiteLeaf";
import { sitesActions } from "../../store/sites";
import { useStateContext } from "../../contexts/StateContext";
import useNavigateToSite from "../../hooks/useNavigateToSite";
import useSiteActions from "../../hooks/useSiteActions";
import StateService from "../../services/StateService";
import { selectSites, useStoreDispatch } from "../../store";
import { readOnlyMode } from "../../store/root";

interface SiteTreeProps {
  siteId?: string;
}

/**
 * Component that represents the site tree.
 * A site tree is the component that will start the process of creating all the tree,
 * @param siteId - the site id. It can be null. If that's the case then the first site selected will be the root.
 * @returns JSX.Element
 */
const SiteTree = ({ siteId }: SiteTreeProps): JSX.Element => {
  const { t } = useI18n();
  const sitesListRef = useRef(null);

  // Rxjs
  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;
  // Redux State Management
  const isReadOnlyMode = useSelector(readOnlyMode);
  const { siteRoot, siteError, sitesExpanded, selectedSiteId } =
    useSelector(selectSites);
  const dispatch = useStoreDispatch();
  const navigateToSite = useNavigateToSite();
  // Tab Menu Actions Context
  const { cancelAction } = useSiteActions();

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  //
  // LifeCycles
  //

  // Fetch Sites
  useEffect(() => {
    dispatch(sitesActions.getAll());
  }, []);

  useEffect(() => {
    if (!siteError) return;
    errorCallback(`${t(siteError.label)}`);
    if (!isReadOnlyMode) cancelAction();
  }, [siteError]);

  useEffect(() => {
    if (!siteRoot.data) return;
    navigateToSite(siteId);
  }, [siteRoot.data]);

  const siteDataFiltered = useMemo(() => {
    return siteRoot?.data?.filter((site) => {
      // Update current snap if the site is selected
      if (site.id === selectedSiteId) {
        dispatch(sitesActions.updateCurrentSnap(site));
      }

      if (!site.parentId) return site;

      for (let i = 0; i < site.ancestors.length; i++) {
        const ancestor = site.ancestors[i];
        if (!sitesExpanded[ancestor.id]?.expanded) return null;
      }
      return site;
    });
  }, [siteRoot.data, sitesExpanded]);

  return (
    <div className="config-site__tree">
      {siteDataFiltered?.length > 0 && (
        <Virtuoso
          ref={sitesListRef}
          data={siteDataFiltered}
          totalCount={siteDataFiltered.length}
          style={{
            height: "100%",
            width: "100%",
          }}
          itemContent={(_, item) => (
            <Accordion
              className="config-site__node"
              data-cr={`site-node-${item.id}`}
              expanded={!!sitesExpanded[item.id]?.expanded}
            >
              <SiteLeaf
                siteData={item}
                depth={item.depth}
                isCollapsable={item.hasChild}
                toggleAccordion={() =>
                  dispatch(
                    sitesActions.changeExpandedSites({
                      [item.id]: {
                        expanded: !sitesExpanded[item.id]?.expanded,
                        parentId: item.parentId,
                      },
                    })
                  )
                }
              />
            </Accordion>
          )}
        />
      )}
    </div>
  );
};

SiteTree.defaultProps = {
  siteId: null,
};
export default SiteTree;
