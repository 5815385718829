import React, { useEffect, useState } from "react";

import {
  CommonIdentifier,
  FloorPlanDevice,
  FloorPlanImage,
  SiteFloorPlan,
  getImageMeta,
  useI18n,
} from "compass-commons";
import { useSelector } from "react-redux";
import { useStateContext } from "../../../../contexts/StateContext";
import { FloorPlansDto } from "../../../../models/floorPlans/FloorPlanDto";
import FloorPlanService from "../../../../services/FloorPlanService";
import StateService from "../../../../services/StateService";
import {
  selectResources,
  selectSites,
  useStoreDispatch,
} from "../../../../store";
import { resourcesActions } from "../../../../store/resources";
import { readOnlyMode, rootActions } from "../../../../store/root";
import { sortByProp } from "../../../../utils/Util";
import ResourceContentFooter from "./ResourceContentFooter";
import ResourceContentHeader from "./ResourceContentHeader";
import ResourceContentMap from "./ResourceContentMap";

interface DeviceContentPanelProps {
  floorPlans: FloorPlansDto;
  errorCallback?: (msg: string) => void;
}

const ResourceContentPanel = (props: DeviceContentPanelProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { floorPlans, errorCallback } = props;
  const [floorPlan, setFloorPlan] = useState<SiteFloorPlan>(null);
  const stateService: StateService = useStateContext();
  const {
    currentMapZoom,
    currentMapCenter,
    currentResourceToPlace,
    actualResourceLocation,
  } = stateService;
  const [isLoading, setIsLoading] = useState(false);
  const [isImageAvailable, setIsImageAvailable] = useState(false);
  const [isGeographical, setIsGeographical] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [selectedDropDownOption, setSelectedDropDownOption] =
    useState<any>(null);
  const [showLabels, setShowLabels] = useState(false);
  const [imageInfo, setImageInfo] =
    useState<{ url: string; width: number; height: number }>(null);

  // Redux
  const { selectedSiteId: siteId } = useSelector(selectSites);
  const {
    resourcesList,
    selectedResource,
    loadingSelectedResource,
    geoReferencedResources,
  } = useSelector(selectResources);
  const dispatch = useStoreDispatch();
  const isReadOnlyMode = useSelector(readOnlyMode);

  const [mutableResourcesList, setMutableResourcesList] = useState([
    ...resourcesList,
  ]);
  const [mutableSelectedResource, setMutableSelectedResource] = useState({
    ...selectedResource,
  });

  const [isSelectPositionEnabled, setIsSelectPositionEnabled] = useState(false);
  const [isDeleteResourceEnabled, setIsDeleteResourceEnabled] = useState(false);

  useEffect(() => {
    setIsSelectPositionEnabled(false);
    setIsDeleteResourceEnabled(
      !isLoading &&
        (selectedResource?.xCoordinate !== null ||
          selectedResource?.yCoordinate !== null)
    );
  }, [isReadOnlyMode]);

  useEffect(() => {
    setMutableSelectedResource(
      selectedResource ? { ...selectedResource } : null
    );
    currentResourceToPlace.next(null);
    if (selectedResource) {
      actualResourceLocation.next({
        deviceOnMap: selectedResource?.deviceOnMap,
        floorPlanId: selectedResource?.floorPlanId,
      });
    }

    setMutableResourcesList([
      ...resourcesList.filter(
        (resource) =>
          !geoReferencedResources.find(
            (geoReferencedResource) => geoReferencedResource.id === resource.id
          )
      ),
      ...geoReferencedResources,
    ]);
  }, [selectedResource, resourcesList]);

  useEffect(() => {
    setIsLoading(loadingSelectedResource);
  }, [loadingSelectedResource]);

  const getOptions = () => {
    const list: CommonIdentifier[] = floorPlans;
    if (list) {
      const sortingIdentifier = (a, b) => (a.id as number) - (b.id as number);
      list.sort(sortingIdentifier);
    }
    let optionsList: unknown[] = list?.map((identifier) => ({
      value: identifier.id,
      label: identifier.name,
    }));
    optionsList = [
      {
        label: translate("devices.geoMap"),
        options: [
          {
            value: "geo",
            label: translate("devices.mapView"),
          },
        ],
      },
      { label: translate("floorPlans.floorPlans"), options: optionsList },
    ];
    return optionsList;
  };

  const loadFloorPlan = (id: number) => {
    setIsLoading(true);
    setIsImageAvailable(false);
    FloorPlanService.getFloorPlanById(siteId, id)
      .then(async (res) => {
        if (res) {
          try {
            res.floorPlanImage = {
              content: await FloorPlanService.getFloorPlanImage(siteId, id),
            } as FloorPlanImage;
            setIsImageAvailable(!!res.floorPlanImage.content);
          } catch (e) {
            errorCallback(translate("floorPlans.failedToLoadImage"));
          }
        }
        setFloorPlan(res);
        setIsLoading(false);

        setSelectedDropDownOption({
          value: res.id,
          label: res.name,
        });
        mutableSelectedResource.floorPlanId = res.id.toString();
      })
      .catch(() => {
        setIsLoading(false);
        errorCallback(translate("floorPlans.failedToLoadFloorPlan"));
      });
  };

  useEffect(() => {
    if (floorPlans) {
      setDropdownOptions(getOptions());
    }
  }, [floorPlans]);

  const setContentHeaderActions = (resource) => {
    if (!resource) return;

    setIsSelectPositionEnabled(
      selectedDropDownOption && !resource.xCoordinate && !resource.yCoordinate
    );
    setIsDeleteResourceEnabled(resource.xCoordinate && resource.yCoordinate);
  };

  const setInitialZoomWorldMap = () => {
    currentMapZoom.next(2); // set zoom lower for world map
    currentMapCenter.next([48, 25]); // set current map center
  };

  const getActualLocationOption = () => {
    if (!actualResourceLocation?.value) return null;

    if (actualResourceLocation?.value.floorPlanId)
      return actualResourceLocation.value.floorPlanId;
    if (actualResourceLocation?.value.deviceOnMap) return "geo";

    return null;
  };

  useEffect(() => {
    if (!mutableSelectedResource) {
      setFloorPlan(null);
      setIsGeographical(false);
      currentMapCenter.next(null);
      currentMapZoom.next(null);
      setSelectedDropDownOption(null);
      currentResourceToPlace.next(null);
      actualResourceLocation.next(null);
      return;
    }

    if (
      mutableSelectedResource.xCoordinate &&
      mutableSelectedResource.yCoordinate
    ) {
      currentResourceToPlace.next(mutableSelectedResource);
    } else {
      currentResourceToPlace.next(null);
    }

    if (mutableSelectedResource.floorPlanId) {
      loadFloorPlan(Number(mutableSelectedResource.floorPlanId).valueOf());
      setIsGeographical(false);
      setContentHeaderActions(mutableSelectedResource);
      return;
    }
    if (mutableSelectedResource.deviceOnMap) {
      setIsGeographical(true);
      setFloorPlan(null);
      setSelectedDropDownOption({
        value: "geo",
        label: translate("devices.mapView"),
      });

      setInitialZoomWorldMap();
      setContentHeaderActions(mutableSelectedResource);
      return;
    }
    setFloorPlan(null);
    setIsGeographical(false);
    setSelectedDropDownOption(null);
  }, [mutableSelectedResource]);

  useEffect(() => {
    const loadImage = async (imageUrl) => {
      await getImageMeta(imageUrl).then((img) => {
        setImageInfo({
          width: img.naturalWidth,
          height: img.naturalHeight,
          url: imageUrl,
        });
      });
    };
    if (floorPlan?.floorPlanImage?.content) {
      const floorUrl = URL.createObjectURL(floorPlan.floorPlanImage.content);
      // eslint-disable-next-line no-console
      loadImage(floorUrl).catch((error) => console.error(error));
    }
  }, [floorPlan]);

  useEffect(() => {
    setIsSelectPositionEnabled(
      !isLoading &&
        isImageAvailable &&
        getActualLocationOption()?.toString() !== floorPlan?.id.toString()
    );
    setIsDeleteResourceEnabled(
      !isLoading &&
        isImageAvailable &&
        getActualLocationOption()?.toString() === floorPlan?.id.toString() &&
        (selectedResource?.xCoordinate !== null ||
          selectedResource?.yCoordinate !== null)
    );
  }, [isLoading, isImageAvailable]);

  const handleSave = async () => {
    try {
      if (!isGeographical && floorPlan) {
        await dispatch(resourcesActions.updateFloorPlan({ siteId, floorPlan }));
        currentResourceToPlace.next(null);
        dispatch(
          resourcesActions.getResourceByResourceMappingId({
            siteId,
            resourceMappingId: mutableSelectedResource.resourceMappingId,
          })
        );
      }
      if (isGeographical && mutableResourcesList && mutableSelectedResource) {
        const geoResources = mutableResourcesList.filter(
          (res) => res.deviceOnMap && res.xCoordinate && res.yCoordinate
        );
        currentResourceToPlace.next(null);
        await dispatch(
          resourcesActions.updateGeoResources({
            siteId,
            devices: geoResources,
          })
        );
        dispatch(
          resourcesActions.getResourceByResourceMappingId({
            siteId,
            resourceMappingId: mutableSelectedResource.resourceMappingId,
          })
        );
        dispatch(
          resourcesActions.getAllGeoReferencedResources({
            siteId,
          })
        );
      }

      dispatch(
        resourcesActions.setResourcesList(
          sortByProp(
            [
              ...mutableResourcesList.filter((mutableResource) =>
                resourcesList.find(
                  (resource) => resource.id === mutableResource.id
                )
              ),
            ],
            "name"
          )
        )
      );
      dispatch(rootActions.activateReadOnlyMode(true));
    } catch (error) {
      dispatch(rootActions.activateEditReadOnlyMode());
    }
  };

  useEffect(() => {
    const save = (ev) => {
      ev.stopImmediatePropagation();
      handleSave();
    };
    document.addEventListener("resourceLocationSave", save);

    return () => {
      document.removeEventListener("resourceLocationSave", save);
    };
  }, [
    isGeographical,
    floorPlan,
    mutableResourcesList,
    mutableSelectedResource,
  ]);

  const setResourceCoordinates = (
    resource: FloorPlanDevice,
    updateState = false,
    x = null,
    y = null
  ) => {
    if (resource) {
      const resourceTemp = updateState ? { ...resource } : resource;

      const newResource = {
        ...resourceTemp,
        deviceOnMap: isGeographical,
        xCoordinate: x,
        yCoordinate: y,
      } as FloorPlanDevice;

      mutableSelectedResource.xCoordinate = String(x);
      mutableSelectedResource.yCoordinate = String(y);

      if (updateState) {
        setMutableSelectedResource(newResource);
      }
    }
  };

  const updateResourcesList = (
    newResource: FloorPlanDevice,
    updateState = false
  ) => {
    const index = mutableResourcesList?.findIndex(
      (res) => res.id === newResource.id
    );
    const updatedResourcesList = updateState
      ? [...mutableResourcesList]
      : mutableResourcesList;
    updatedResourcesList[index] = newResource;

    if (updateState) {
      setMutableResourcesList(updatedResourcesList);
    }
  };

  const updateFloorPlan = (isDelete = false) => {
    const updatedFloorPlan: SiteFloorPlan = { ...floorPlan };
    const deviceList = updatedFloorPlan?.deviceList.filter(
      (d) => d.id !== mutableSelectedResource.id
    );
    updatedFloorPlan.deviceList = deviceList;
    if (!isDelete) updatedFloorPlan?.deviceList.push(mutableSelectedResource);
    setFloorPlan(updatedFloorPlan);
    return updatedFloorPlan;
  };

  const handlePlaceDevice = () => {
    const notFoundInDeviceList =
      floorPlan?.deviceList?.findIndex(
        (dev) => dev?.id === mutableSelectedResource?.id
      ) === -1;
    currentResourceToPlace.next(mutableSelectedResource);
    if (isGeographical) {
      mutableSelectedResource.floorPlanId = null;
      mutableSelectedResource.deviceOnMap = true;
      setResourceCoordinates(mutableSelectedResource, false);
      updateResourcesList(mutableSelectedResource, true);
    } else if (
      notFoundInDeviceList ||
      (mutableSelectedResource.floorPlanId &&
        !mutableSelectedResource.xCoordinate &&
        !mutableSelectedResource.yCoordinate)
    ) {
      setResourceCoordinates(mutableSelectedResource, false);
      const updatedFloorPlan = updateFloorPlan();
      mutableSelectedResource.floorPlanId = updatedFloorPlan.id.toString();
      mutableSelectedResource.deviceOnMap = false;
      updateResourcesList(mutableSelectedResource, true);
    }
    setContentHeaderActions(mutableSelectedResource);
  };

  const handleDeleteDevice = () => {
    if (!mutableSelectedResource) return;
    mutableSelectedResource.yCoordinate = null;
    mutableSelectedResource.xCoordinate = null;
    currentResourceToPlace.next(null);
    if (!isGeographical) {
      updateFloorPlan(true);
    }

    updateResourcesList(mutableSelectedResource, true);
    setContentHeaderActions(mutableSelectedResource);
  };

  const updateDeviceLocation = (x, y) => {
    if (mutableSelectedResource?.deviceOnMap) {
      setResourceCoordinates(mutableSelectedResource, false, x, y);
      updateResourcesList(mutableSelectedResource, true);
    } else {
      const device = floorPlan?.deviceList?.find(
        (dev) => dev.id === mutableSelectedResource?.id
      );
      setResourceCoordinates(device, false, x, y);
      updateFloorPlan();
    }
    setContentHeaderActions(mutableSelectedResource);
  };

  const handleZoom = (zoom, center) => {
    currentMapZoom.next(zoom);
    currentMapCenter.next(center);
  };

  const handleSelect = (option) => {
    if (option) {
      if (option.value === "geo") {
        setIsGeographical(true);
        setFloorPlan(null);
        setSelectedDropDownOption({
          value: "geo",
          label: translate("devices.mapView"),
        });
        setIsSelectPositionEnabled(
          actualResourceLocation.value !== null &&
            !actualResourceLocation.value.deviceOnMap
        );
        setInitialZoomWorldMap();
      } else {
        setIsGeographical(false);
        const floorPlanId = option.value;
        if (!floorPlan || floorPlan.id !== floorPlanId) {
          loadFloorPlan(floorPlanId);
        }
      }
    }
  };

  const NoDeviceSelected = (): JSX.Element => {
    return (
      <div data-cr="device-not-selected" className="device-not-selected">
        {translate("devices.resourceNotSelected")?.toUpperCase()}
      </div>
    );
  };

  return (
    <>
      {mutableSelectedResource && floorPlans && siteId ? (
        <div className="device-content-panel" data-cr="device-content-panel">
          <ResourceContentHeader
            handleSelect={handleSelect}
            isLoading={isLoading}
            dropdownOptions={dropdownOptions}
            handlePlace={handlePlaceDevice}
            handleDelete={handleDeleteDevice}
            translation={translate}
            selectedLocationOption={selectedDropDownOption}
            isSelectPositionEnabled={isSelectPositionEnabled}
            isDeleteResourceEnabled={isDeleteResourceEnabled}
            actualResourceLocation={getActualLocationOption()}
          />
          <ResourceContentMap
            siteResources={mutableResourcesList}
            resource={mutableSelectedResource}
            isGeographical={isGeographical}
            isLoading={isLoading}
            floorPlan={floorPlan}
            showLabels={showLabels}
            translation={translate}
            updateDeviceLocation={updateDeviceLocation}
            currentMapZoom={currentMapZoom}
            currentMapCenter={currentMapCenter}
            handleZoom={handleZoom}
            currentPlaceableResource={currentResourceToPlace}
            imageInfo={imageInfo}
          />
          <ResourceContentFooter
            handleShowLabels={setShowLabels}
            showLabels={showLabels}
            translation={translate}
          />
        </div>
      ) : (
        <NoDeviceSelected />
      )}
    </>
  );
};

ResourceContentPanel.defaultProps = {
  errorCallback: () => null,
};

export default ResourceContentPanel;
